import { FormikErrors } from "formik";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import styled from "styled-components";

import { DismissModalButton } from "@src/Components/Modal/DismissModalButton";
import { ValueType } from "@src/DynamicForm/initFormConfig";
import { useToggle } from "@src/Hooks/toggle";
import { initialMapState, mapReducer } from "@src/Map/mapReducer";
import { useMapSettings } from "@src/Map/mapState";
import { PositionSelector } from "@src/ServicesDashboard/Infrastructure/Inventory/PositionSelector";

import { Clickable } from "../Buttons/Clickable";
import { PrimaryButton } from "../Buttons/Primary";
import { Modal, Title } from "../Modal/Modal";
import { baseInputStyles } from "./Input";
import { InputErrors } from "./InputGroup";

const PositionInputButton = styled(Clickable)`
  ${baseInputStyles}
  &:disabled {
    background: ${({ theme }) => theme.backgroundLight};
    opacity: 1;
    &:focus {
      outline: none;
    }
  }
`;
const ContentWrapper = styled.div`
  width: 50vw;
`;

const SetPositionWrapper = styled.div`
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
`;

const InputErrorsWrapper = styled.span`
  grid-column: 2;
`;

export type PositionInputValue = { longitude: number; latitude: number };

interface PositionInputProps<E> {
  name: string;
  value: PositionInputValue;
  setFieldValue: (field: string, value: ValueType, shouldValidate?: boolean) => void;
  errors: FormikErrors<E>;
  disabled?: boolean;
}

export function PositionInput<E>({
  name,
  value,
  setFieldValue,
  errors,
  disabled = false
}: PositionInputProps<E>) {
  const position = useMemo(() => value, [value]);
  const [mapState, dispatch] = useReducer(mapReducer, initialMapState);
  const { longitude: lng, latitude: lat } = mapState;

  const mapIsReady = useMapSettings(mapState, dispatch);
  const { state, toggle } = useToggle();

  const setPosition = useCallback(() => {
    setFieldValue(name, { longitude: lng, latitude: lat }, false);
    toggle();
  }, [name, setFieldValue, lng, lat, toggle]);

  useEffect(() => {
    if (!mapIsReady) {
      dispatch({
        type: "init",
        center: { lat: position.latitude, lng: position.longitude },
        zoom: 15
      });
    }
  }, [position, mapIsReady]);

  if (!mapIsReady) return null;
  return (
    <>
      <PositionInputButton disabled={disabled} onClick={toggle}>
        {position.latitude && position.longitude
          ? `${position.latitude}, ${position.longitude}`
          : "Select Position"}
      </PositionInputButton>
      <InputErrorsWrapper>
        <InputErrors name={name} errors={errors} />
      </InputErrorsWrapper>

      <Modal show={state} onOutsideClick={toggle}>
        <DismissModalButton onClick={toggle} />
        <ContentWrapper>
          <Title>Select Position</Title>
          <PositionSelector
            fieldValue={{ lat: mapState.latitude, lng: mapState.longitude }}
            mapState={mapState}
            dispatch={dispatch}
            hideLabel
            mapHeight="45vh"
          />
          <SetPositionWrapper>
            <PrimaryButton onClick={setPosition}>Select Position</PrimaryButton>
          </SetPositionWrapper>
        </ContentWrapper>
      </Modal>
    </>
  );
}
