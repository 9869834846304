// redirectNetwork returns the new path that we should navigate to when switching networks
// it strips parts of the URL that refer to specific resources that should no longer be accessible
export function redirectNetwork(pathname: string, network: string) {
  let match = pathname.match(/^(?<base>\/app\/[^/]+\/mpn)\/network\/(add|[^/]+\/edit)$/);
  if (match && match.groups) {
    return `${match.groups.base}/${network}`;
  }

  match = pathname.match(/^(?<base>\/app\/(?<org>[^/]+)\/mpn)\/(?<network>[^/]+)\/(?<path>.*)$/);
  if (!(match && match.groups)) return null;

  return `${match.groups.base}/${network}/${match.groups.path.replace(
    /\/(core|manage)\/[^/]+$/,
    ""
  )}`;
}
