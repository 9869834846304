import "react-loading-skeleton/dist/skeleton.css";

import React, { lazy, Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { LoadingPage } from "@src/Components/Loading/LoadingPage";
import { NotFoundPage } from "@src/Components/Navigation/NotFoundPage";
import { ProtectedDashboard } from "@src/Components/Navigation/ProtectedRoute";

import { Licenses } from "./About/Licenses";
import { LicensesView } from "./About/LicensesView";
import { LicensesWeb } from "./About/LicensesWeb";
import { Error } from "./Auth/Error";
import { Login } from "./Auth/Login";
import { Recover } from "./Auth/Recover";
import { ApolloProviderWithOrgCtx } from "./Clients/Apollo/ApolloProvider";
import { DefaultPageRedirect } from "./DefaultPageRedirect";
import { ErrorReviewPage } from "./Error/ErrorPage";
import { NoDashboard } from "./Error/NoDashboard";
import { NoOrg } from "./Error/NoOrg";
import { InitialRedirect } from "./InitialRedirect";
import { Layout } from "./Layout";
import { InitialNetworkRedirect } from "./MPNDashboard/MPNRedirects";
import { EditNetworkForm } from "./MPNDashboard/Network/EditNetworkForm";
import { NewNetworkForm } from "./MPNDashboard/Network/NewNetworkForm";
import { OrgRedirect } from "./OrgRedirect";
import { UserSettings } from "./UserSettings/UserSettings";

const AboutPage = lazy(() => import("./About/AboutPage"));
const AddNetworkPage = lazy(() => import("./MPNDashboard/Network/AddNetworkPage"));
const AdminDashboardRoutes = lazy(() => import("./AdminDashboard/AdminRoutes"));
const OrgLessAdminRoutes = lazy(() => import("./AdminDashboard/OrgLessAdminRoutes"));
const Auth = lazy(() => import("./Auth/Auth"));
const MPNRoutes = lazy(() => import("./MPNDashboard/MPNRoutes"));
const ServicesDashboardRoutes = lazy(
  () => import("@src/ServicesDashboard/ServicesDashboardRoutes")
);

export function App() {
  return (
    <>
      <Routes>
        <Route index element={<InitialRedirect />} />

        <Route path="app" element={<Layout />}>
          <Route path="services" element={<OrgRedirect />} />
          <Route path="service-designer" element={<OrgRedirect />} />
          <Route path="publisher" element={<OrgRedirect />} />
          <Route path="infrastructure" element={<OrgRedirect />} />
          <Route path="reports" element={<OrgRedirect />} />
          <Route path="settings" element={<OrgRedirect />} />

          <Route path="no-org" element={<NoOrg />} />

          <Route path="admin/*" element={<OrgLessAdminRoutes />} />

          <Route path=":orgCtx" element={<ApolloProviderWithOrgCtx />}>
            <Route index element={<DefaultPageRedirect />} />

            <Route path="no-dashboard" element={<NoDashboard />} />

            <Route
              path="services/*"
              element={
                <ProtectedDashboard dashboard="services">
                  <ServicesDashboardRoutes />
                </ProtectedDashboard>
              }
            />

            <Route path="mpn" element={<Outlet />}>
              <Route index element={<InitialNetworkRedirect />} />
              <Route
                path=":networkCtx/*"
                element={
                  <ProtectedDashboard dashboard="mpn">
                    <MPNRoutes />
                  </ProtectedDashboard>
                }
              />
              <Route
                path="network"
                element={
                  <ProtectedDashboard dashboard="mpn">
                    <AddNetworkPage />
                  </ProtectedDashboard>
                }
              >
                <Route index element={<Navigate to="add" replace />} />
                <Route path="add" element={<NewNetworkForm />} />
                <Route path=":networkCtx/edit" element={<EditNetworkForm />} />
              </Route>
            </Route>

            <Route path="admin/*" element={<AdminDashboardRoutes />} />
          </Route>

          <Route path="about">
            <Route index element={<AboutPage />} />
            <Route path="licenses" element={<LicensesView />}>
              <Route path="web" element={<LicensesWeb />} />
              <Route path=":component" element={<Licenses />} />
            </Route>
          </Route>

          <Route path="user/settings" element={<UserSettings />} />
          <Route path="error" element={<ErrorReviewPage />} />
        </Route>

        <Route
          path="auth"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Auth />
            </Suspense>
          }
        >
          <Route path="login" element={<Login />} />
          <Route path="recovery" element={<Recover />} />
          <Route path="error" element={<Error />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Toaster />
    </>
  );
}
