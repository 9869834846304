import React from "react";
import { useSearchParams } from "react-router-dom";
import { Column } from "react-table";
import styled from "styled-components";

import { Skeleton } from "@src/Components/Skeleton";
import { TableActionButton, Td, Tr } from "@src/Components/Table/Table";
import {
  ActionsCellProps,
  TableActions,
  TableComponent
} from "@src/Components/Table/TableComponent";
import { EyeIcon } from "@src/Components/Table/tableIcons";
import { TableSkeleton } from "@src/Components/Table/TableSkeleton";
import { EventLogEntry, PageInfo } from "@src/Generated/graphql";
import { useFilterByFields } from "@src/Hooks/filterByFields";
import { LogsFilter, logTypeFilters } from "@src/LogsPanel/LogsFilter";

import { AuditLogNode, RefetchVariables } from "./fetchLogs";
import { LogsPagination, Pagination } from "./LogsPagination";

const StyledTr = styled(Tr)`
  height: 45px;
`;
const StyledTd = styled(Td)`
  font-size: 12px;
  letter-spacing: initial;
  font-weight: 500;
`;

export type ReportLog = Omit<AuditLogNode | EventLogEntry, "__typename">;
export type ReportsColumns = Array<Column<ReportLog>>;

interface ReportsLogsTableProps {
  reportLogs: ReportLog[];
  columns: ReportsColumns;
  hideableColumns: string[];
  openDetailsModal: (log: ReportLog) => void;
  selectedFilters: string[];
  fetchLogs: (variables?: Partial<RefetchVariables>) => void;
  pageInfo: PageInfo;
  totalCount: number;
  loading: boolean;
}

export function ReportsLogsTable({
  reportLogs,
  columns,
  hideableColumns,
  openDetailsModal,
  selectedFilters,
  fetchLogs,
  pageInfo,
  totalCount,
  loading
}: ReportsLogsTableProps) {
  const [searchParams] = useSearchParams();
  const pageSize = Number(searchParams.get("pageSize"));
  const actionColumn: Column<TableActions<ReportLog>> = {
    Header: "details",
    accessor: "_actions",
    disableSortBy: true,
    Cell: ({ row }: ActionsCellProps<ReportLog>) => {
      const rowLog = row.original;
      return (
        <TableActionButton
          title={`details for log on ${rowLog.time}`}
          onClick={() => {
            openDetailsModal(rowLog);
          }}
        >
          <EyeIcon />
        </TableActionButton>
      );
    }
  };

  const hiddenColumns = hideableColumns.filter(filter => !selectedFilters.includes(filter));

  const { selectedFilters: selectedTypeFilters, toggleFilterTag } =
    useFilterByFields(logTypeFilters);
  const selectedLogs = reportLogs.filter(log => selectedTypeFilters.includes(log?.type));

  return !loading ? (
    <>
      <LogsFilter
        logs={reportLogs}
        selectedFilters={selectedTypeFilters}
        toggleFilterTag={toggleFilterTag}
        filtersPadding="16px 0px"
      />
      <TableComponent<ReportLog>
        columnHeaders={columns}
        items={selectedLogs}
        rightActions={actionColumn}
        hiddenColumns={hiddenColumns}
        StyledTr={StyledTr}
        StyledTd={StyledTd}
        pageSize={pageSize}
        tableMargin="0px auto"
        disableSort
      />
      {pageInfo && (
        <LogsPagination
          fetchLogs={fetchLogs}
          pageInfo={pageInfo}
          totalCount={totalCount}
          logsShown={reportLogs.length}
        />
      )}
    </>
  ) : (
    <>
      <TableSkeleton
        columns={Array(columns.length + 1).fill(null)}
        rowNumber={Number(pageSize)}
        StyledTr={StyledTr}
        StyledTd={StyledTd}
      />
      <Pagination>
        <Skeleton width={150} />
      </Pagination>
    </>
  );
}
