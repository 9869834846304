import { FormEvent, MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";

import {
  LoginFlow,
  RecoveryFlow,
  SettingsFlow,
  UiNode,
  UiNodeAttributes,
  UiNodeInputAttributes,
  UpdateLoginFlowBody,
  UpdateRecoveryFlowBody,
  UpdateSettingsFlowBody
} from "@ory/client";

export type Methods =
  | "oidc"
  | "password"
  | "profile"
  | "totp"
  | "webauthn"
  | "link"
  | "lookup_secret";

export type Values = Partial<UpdateLoginFlowBody | UpdateRecoveryFlowBody | UpdateSettingsFlowBody>;

type State<T> = {
  values: T;
  isLoading: boolean;
};

export interface useSelfServiceProps<T extends Values> {
  flow?: LoginFlow | SettingsFlow | RecoveryFlow;
  only?: Methods;
  onSubmit: (values: T) => Promise<void>;
  isStillMounted?: MutableRefObject<boolean>;
}

export function useSelfService<T>({
  flow,
  only,
  onSubmit,
  isStillMounted = { current: true }
}: useSelfServiceProps<T>) {
  const [state, setState] = useState<State<T>>({
    isLoading: false,
    values: {} as T
  });

  const filteredNodes = useMemo(
    () =>
      flow?.ui.nodes.filter(({ group }) => {
        if (!only) return true;
        if (only === "oidc") return group === only;
        return group === "default" || group === only;
      }) || [],
    [flow?.ui.nodes, only]
  );

  useEffect(() => {
    if (!flow) return;

    const initialValues = {} as T;
    filteredNodes.forEach(node => {
      if (isUiNodeInputAttributes(node.attributes)) {
        if (node.attributes.type === "button") return;
        initialValues[node.attributes.name as keyof T] = node.attributes.value;
      }
    });

    setState(prevState => ({
      ...prevState,
      values: initialValues
    }));
  }, [flow, filteredNodes]);

  const onChange = useCallback(
    (id: keyof T, value: string) => {
      setState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          [id]: value
        }
      }));
    },
    [setState]
  );

  const handleSubmit = useCallback(
    (e: MouseEvent | FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (state.isLoading) return Promise.resolve();

      setState(prevState => ({
        ...prevState,
        isLoading: true
      }));

      return onSubmit(state.values).finally(() => {
        // We wait for reconciliation and update the state after 50ms
        // Done submitting - update loading status
        isStillMounted.current &&
          setState(prevState => ({
            ...prevState,
            isLoading: false
          }));
      });
    },
    [setState, state, onSubmit, isStillMounted]
  );

  return {
    filteredNodes,
    state,
    handleSubmit,
    onChange
  };
}

export function isUiNodeInputAttributes(
  attributes: UiNodeAttributes
): attributes is UiNodeInputAttributes {
  return attributes.node_type === "input";
}

export function getNodeId(node: UiNode) {
  const { attributes } = node;
  return !isUiNodeInputAttributes(attributes)
    ? attributes.id
    : node.group === "oidc"
    ? `${attributes.name}-${attributes.value}`
    : attributes.name;
}
