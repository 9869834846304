import { UseSelectStateChange } from "downshift";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { Dropdown } from "@src/Components/Dropdown";
import { useNetworkCtx } from "@src/Hooks/networkCtx";
import { useOrgCtx } from "@src/Hooks/orgCtx";

import { allCoresOption } from "./allCoresOption";
import { NetworkElement } from "./Infrastructure/NetworkElement";

export const CoreSelectorWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 2fr;
  align-items: baseline;
  justify-items: end;
  gap: 10px;
`;

export const DropdownWrapper = styled.div`
  width: 100%;
`;

export const NoCoresMessage = styled.p`
  font-weight: 500;
  font-size: 20px;
  color: ${({ theme }) => theme.darkGrey};
`;

export function getCoreIdentifier(core: NetworkElement) {
  if (!core) return "";
  if (core.uid === allCoresOption.uid) return core.displayname;
  return `${core?.displayname} - ${core?.uid}`;
}

interface CoreDropdownProps {
  coresList: NetworkElement[];
  onSelectCore: (core: UseSelectStateChange<Partial<NetworkElement>>) => void;
  allowEmptyStart?: boolean;
  initialCore?: Partial<NetworkElement>;
}

export function CoresDropdown({
  coresList,
  initialCore,
  onSelectCore,
  allowEmptyStart = false
}: CoreDropdownProps) {
  const defaultCore = !allowEmptyStart ? initialCore || getFirstCore(coresList) : initialCore;
  if (!defaultCore && !allowEmptyStart) return null;
  return (
    <DropdownWrapper>
      <Dropdown
        items={coresList}
        getItemDisplayName={getCoreIdentifier}
        getItemKey={getCoreIdentifier}
        initialSelectedItem={defaultCore}
        placeholder="Select a Core"
        onSelectedItemChange={onSelectCore}
      />
    </DropdownWrapper>
  );
}

export function getFirstCore(cores: { uid: string }[]) {
  return cores.sort((a, b) => a.uid.localeCompare(b.uid)).at(0);
}

export function NoCores() {
  const orgCtx = useOrgCtx();
  const networkCtx = useNetworkCtx();

  return (
    <NoCoresMessage>
      No Cores have been created in this network.{" "}
      <NavLink to={`/app/${orgCtx}/mpn/${networkCtx}/infrastructure/inventory/core/add`}>
        Add a new one.
      </NavLink>
    </NoCoresMessage>
  );
}
