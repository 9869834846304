/* eslint-disable @typescript-eslint/triple-slash-reference */
/// <reference path="./defines.d.ts" />
/// <reference path="./imports.d.ts" />
/// <reference path="./Generated/graphql.d.ts" />
import "core-js";
import "regenerator-runtime/runtime";
import "whatwg-fetch";

import React, { ReactNode } from "react";
import { createRoot } from "react-dom/client";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { ApolloProvider } from "@apollo/client";
import { getApolloClient } from "@src/Clients/Apollo/apolloClient";
import { queryClient } from "@src/Clients/ReactQuery/reactQueryClient";
import { GlobalStyles } from "@src/Components/GlobalStyles";
import { theme } from "@src/Components/theme";
import { SvgDefTrackerProvider } from "@src/Hooks/svgDef";

import { App } from "./App";
import { SessionProvider } from "./Session";

const rootEl = document.getElementById("root");
export const root = createRoot(rootEl);

const orglessApolloClient = getApolloClient();

function Providers({ children }: { children: ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles />
      <SvgDefTrackerProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <SessionProvider>
              <ApolloProvider client={orglessApolloClient}>{children}</ApolloProvider>
            </SessionProvider>
          </BrowserRouter>
        </ThemeProvider>
      </SvgDefTrackerProvider>
      {REACT_QUERY_SHOW_DEVTOOLS && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}

document.addEventListener("DOMContentLoaded", () =>
  root.render(
    <Providers>
      <App />
    </Providers>
  )
);

if (module.hot) {
  module.hot.accept("./App", () => {
    /* eslint-disable @typescript-eslint/no-var-requires */
    const { App: NewApp } = require("./App");

    root.render(
      <Providers>
        <NewApp />
      </Providers>
    );
  });
}
