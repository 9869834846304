import { AxiosError } from "axios";
import React, { ReactNode } from "react";
import styled from "styled-components";

const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.error};
  margin-bottom: 2em;
`;

interface FormErrorContainerProps {
  error: AxiosError;
  children: ReactNode;
}

export function FormErrorContainer({ error, children }: FormErrorContainerProps) {
  if (!error) return null;
  const errorResponseData = error.response?.data;
  return (
    <ErrorContainer>
      {children}

      <p>{error.message}</p>
      {errorResponseData && (
        <>
          <p>{errorResponseData.message}</p>
          <p>{errorResponseData.reason}</p>
        </>
      )}
    </ErrorContainer>
  );
}
