export const headerNetworksKey = "header-network-fetch";

export const mpnRefetchInterval = 5000;

export interface QueryCallbacks<T = object> {
  onComplete?: (data?: T) => void;
  onError?: (error: Error) => void;
}

export interface MPNRequestParams {
  path: string;
  orgId?: string;
  networkId?: string;
  coreId?: string;
}

export function mpnRequestUrl({ path, orgId, networkId, coreId }: MPNRequestParams) {
  const requestUrl = new URL(path, window.location.origin);

  if (orgId)
    requestUrl.searchParams.append(
      "filter",
      `relatedParty[?(@.@referredType==Organization && @.id==${orgId})]`
    );

  if (networkId)
    requestUrl.searchParams.append(
      "filter",
      `resourceRelationship[?(@.relationshipType==targets && @.resource.@referredType==Network && @.resource.id==${networkId})]`
    );

  if (coreId)
    requestUrl.searchParams.append(
      "filter",
      `resourceRelationship[?(@.relationshipType==targets && @.resource.@referredType==Core && @.resource.id==${coreId})]`
    );

  return requestUrl;
}

export function mpnManagerRequestUrl({
  path,
  orgId,
  networkId
}: {
  path: string;
  orgId: string;
  networkId: string;
}) {
  const requestUrl = new URL(path, window.location.origin);

  requestUrl.searchParams.append(
    "filter",
    `$[?(@.relatedParty[?(@.@referredType=="Organization" && @.id=="${orgId}")])]`
  );

  requestUrl.searchParams.append(
    "filter",
    `$[?(@.supportingResource[?(@.@referredType=="Network" && @.id=="${networkId}")])]`
  );

  return requestUrl;
}

export type SimRequestParams = {
  networkId: string;
  coreId: string;
  imsiFilter?: string;
} & ({ page: string; size: string } | { page?: never; size?: never });

export function mpnSIMRequestUrl({ networkId, coreId, page, size, imsiFilter }: SimRequestParams) {
  const path = "/mobile-networks/sim/tmf-api/resourceInventoryManagement/v4/resource";
  const requestUrl = new URL(path, window.location.origin);
  requestUrl.searchParams.append(
    "filter",
    `$[?(@.resourceRelationship[?(@.relationshipType=="targets" && @.resource.@referredType=="Network" && @.resource.id=="${networkId}")])]`
  );

  requestUrl.searchParams.append(
    "filter",
    `$[?(@.resourceRelationship[?(@.relationshipType=="targets" && @.resource.@referredType=="Core" && @.resource.id=="${coreId}")])]`
  );

  if (size && page) {
    requestUrl.searchParams.set("offset", size ? `${+size * (+page - 1)}` : "0");
    requestUrl.searchParams.set("limit", size);
  }

  if (imsiFilter) {
    requestUrl.searchParams.append(
      "filter",
      `$[?(@.resourceCharacteristic[?(@.name=="IMSI" && @.value *="${imsiFilter}")])]`
    );
  }

  return requestUrl;
}
