import { AxiosError } from "axios";
import React from "react";
import toast from "react-hot-toast";

import { ToastNotification } from "@src/Components/ToastNotification";

export function toastError(err: AxiosError) {
  const { title, msg } = getErrorMessages(err);
  toast.error(<ToastNotification title={title} msg={msg} />, {
    style: {
      maxWidth: 700
    }
  });
}

function getErrorMessages(err: AxiosError): { title: string; msg?: string } {
  if (err && err.response?.data) {
    const errorData = err.response?.data;

    if (errorData?.message || errorData?.reason)
      return {
        title: errorData?.message ?? errorData?.reason,
        msg: !errorData?.message ? "" : errorData?.reason ?? ""
      };
  }

  return {
    title: "The application has encountered an unknown error.",
    msg: "The request sent could not be processed properly"
  };
}
