import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";

import AdminDashboard from "@img/admin-dashboard.svg";
import DownArrow from "@img/arrow_light_down.svg";
import UpArrow from "@img/arrow_light_up.svg";
import Dashboards from "@img/dashboards.svg";
import ServicesDashboard from "@img/services.svg";
import MPNDashboard from "@img/sim-card.svg";
import User from "@img/user.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { TextButton } from "@src/Components/Buttons/Text";
import { DisableableNavLink } from "@src/Components/Navigation/DisableableNavLink";
import { useDefaultOrg } from "@src/Hooks/defaultOrg";
import { useMPNDashboard } from "@src/Hooks/mpnDashboard";
import { useOrgCtx } from "@src/Hooks/orgCtx";
import { useServicesDashboard } from "@src/Hooks/servicesDashboard";
import { useToggle } from "@src/Hooks/toggle";
import { useCurrentDashboard } from "@src/Hooks/useCurrentDashboard";

import { useLogoutHandler } from "./Auth/Logout";
import { HeaderOrgSelector } from "./Header/HeaderOrgSelector";
import { HeaderItem, HeaderLabel, MenuLink, Separator, UserMenu } from "./Header/HeaderStyles";
import { NetworkSelector } from "./MPNDashboard/NetworkSelector";
import { ServiceDashboardHeaderItems } from "./ServicesDashboard/ServiceDashboardHeaderItems";
import { useSession } from "./Session";
import { ClientLogo } from "./UserSettings/ClientLogo";

const headerPadding = 32;

export const HeaderWrapper = styled.header`
  grid-area: header;
  height: 50px;
  background: white;
  display: flex;
  gap: 10px;
  align-items: stretch;
  justify-content: flex-end;
  padding: 0 ${headerPadding}px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
`;

const DashboardsMenu = styled(UserMenu)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  justify-items: center;
`;

const ServicesDashboardIcon = styled(ServicesDashboard)`
  height: 50px;
`;
const AdminDashboardIcon = styled(AdminDashboard)`
  height: 50px;
`;
const MPNDashboardIcon = styled(MPNDashboard)`
  height: 50px;
  width: 42px;
`;

const DashboardIconLink = styled(DisableableNavLink)<{ active: boolean }>`
  display: flex;
  width: 80px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  text-decoration: none;
  color: inherit;
  font-weight: 600;
  padding: 8px;
  border-radius: 4px;

  color: ${({ theme, active }) => active && theme.primary};
  background-color: ${({ theme, active }) => active && theme.backgroundLight};
  & svg {
    fill: ${({ theme, active }) => active && theme.primary};
  }

  &:hover {
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.backgroundLight};
    & svg {
      fill: ${({ theme }) => theme.primary};
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.grey};
    background-color: white;
    border: 2px solid ${({ theme }) => theme.grey};
    & svg {
      fill: ${({ theme }) => theme.grey};
    }
  }
`;

export const SeparatorUser = styled(Separator)`
  &:first-of-type {
    margin-top: auto;
  }
`;

export const MenuButton = styled(TextButton)`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #666666;
`;

export function Header() {
  const { state: isOpenUser, toggle: toggleMenuUser, off: closeMenuUser } = useToggle();
  const orgCtx = useOrgCtx();
  const { org: defaultOrg } = useDefaultOrg();

  const {
    state: isOpenDashboards,
    toggle: toggleMenuDashboards,
    off: closeMenuDashboards
  } = useToggle();

  const { session } = useSession();
  const userName = session?.identity?.traits?.name;

  const logout = useLogoutHandler();

  const userMenu = useRef();
  useOnClickOutside(userMenu, closeMenuUser);

  const dashboardsMenu = useRef();
  useOnClickOutside(dashboardsMenu, closeMenuDashboards);

  const { pathname } = useLocation();

  const { isServices, isMPN, isAdminDashboard } = useCurrentDashboard(pathname);
  const { allowed: allowMPN } = useMPNDashboard();
  const { allowed: allowServices } = useServicesDashboard();

  const org = orgCtx || defaultOrg;

  const disableServicesDashboard = !allowServices || !org;
  const disableMPNDashboard = !allowMPN || !org;

  return (
    <HeaderWrapper>
      {(isServices || isMPN || isAdminDashboard) && <HeaderOrgSelector />}

      {isMPN && <NetworkSelector />}

      <ClientLogo />

      {isServices && <ServiceDashboardHeaderItems />}

      <HeaderItem ref={dashboardsMenu}>
        <Clickable onClick={toggleMenuDashboards}>
          <Dashboards height={22} width={22} />
          <HeaderLabel>Dashboards</HeaderLabel>
          {isOpenDashboards ? (
            <UpArrow height={14} width={14} />
          ) : (
            <DownArrow height={14} width={14} />
          )}
        </Clickable>
        {isOpenDashboards && (
          <DashboardsMenu onClick={closeMenuDashboards}>
            <DashboardIconLink
              to={`/app/${org}/services`}
              active={isServices}
              disabled={disableServicesDashboard}
            >
              <ServicesDashboardIcon />
              <span>Services Dashboard</span>
            </DashboardIconLink>
            <DashboardIconLink to={`/app/${org}/mpn`} active={isMPN} disabled={disableMPNDashboard}>
              <MPNDashboardIcon />
              <span>LTE/5G Networks</span>
            </DashboardIconLink>
            <DashboardIconLink to={`/app/${org}/admin`} active={isAdminDashboard}>
              <AdminDashboardIcon />
              <span>Admin Dashboard</span>
            </DashboardIconLink>
          </DashboardsMenu>
        )}
      </HeaderItem>

      <HeaderItem ref={userMenu}>
        <Clickable onClick={toggleMenuUser}>
          <User height={30} width={30} />
          <HeaderLabel>{userName}</HeaderLabel>
          {isOpenUser ? <UpArrow height={14} width={14} /> : <DownArrow height={14} width={14} />}
        </Clickable>
        {isOpenUser ? (
          <UserMenu>
            <User height={160} width={160} />
            <SeparatorUser />
            <MenuLink to="/app/user/settings">Profile settings</MenuLink>
            <SeparatorUser />
            <MenuButton onClick={logout}>Sign out</MenuButton>
          </UserMenu>
        ) : null}
      </HeaderItem>
    </HeaderWrapper>
  );
}
